import React, { Component } from 'react';
import { BrowserRouter as Router, Route ,Switch} from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import Loginc from './login/login';
import Homec from './home/home';
import Myclass from './myclass/myclass';
// import Videoshow from './videoshow/videoshow';
import './App.css';
import './reset.css';
import './font/iconfont.css';
moment.locale('zh-cn');

const Home = () => (
  <Router>
    <Homec />
  </Router>
)

const Login = () => (
  <Router>
    <Loginc />
  </Router>
)

const MyClass = () => (
  <Router>
    <Myclass />
  </Router>
)

// const VideoShow = () => (
//   <Router>
//     <Videoshow />
//   </Router>
// )

export default class App extends Component {
  render() {
    return (
      <ConfigProvider locale={zhCN}>
        <Router>
          <Switch className="App">
              <Route path="/" exact component={Home}></Route>
              <Route path="/login" component={Login}></Route>
              <Route path="/myclass" component={MyClass}></Route>
          </Switch>
        </Router>
      </ConfigProvider>
    );
  }
}


// export default App;