import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap';
axios.interceptors.response.use(response => {
  console.log(response.data.code)
  if (response.data.status == 500) {
    console.log('服务器返回500，请排查所填请求参数是否正确');
  }else if (response.data.code == 401) { // 如果 token 过期后台返回状态码为 401 (根据情况修改状态码)
    // 删除缓存中的 token
    alert("登录信息已失效，请重新登录")
    window.localStorage.removeItem('token')
    window.location.href = '/login'
  }
  return response;
}, error => {
  if (error.response.data.code == 401) { // 如果 token 过期后台返回状态码为 401 (根据情况修改状态码)
    // 删除缓存中的 token
    window.localStorage.removeItem('token')
    window.location.href = '/login'
  }
  console.log(error.response) // 如果请求出错
  if (error.response) {
    switch (error.response.status) {
      case 500:
        console.log('接口请求出现异常');
    }
    return Promise.reject(error.response.data);
  } else {
    console.log('前端请求未找到相应地址，请检查服务器是否正常，网络连接是否正常');
  }
});
ReactDOM.render(
  <React.StrictMode>
    <App />,
  </React.StrictMode>, 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
