import React from 'react';
import image_1 from '../images/1.png'
import image_2 from '../images/2.png'
import image_3 from '../images/3.png'
import SwipePlayer from './SwipePlayer'
 
class LunBoT extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      images: [image_1, image_2, image_3]
    }
  }
 
  render () {
    return (
      <div>
        <SwipePlayer images={this.state.images}></SwipePlayer>
      </div>
    )
  }
}

export default LunBoT;