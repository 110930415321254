import React, { Component } from 'react';
import axios from 'axios';
import YOYO from '../images/YOYOxiaocheng.png';
import YoCode from '../images/yocode.png';
import LoginButton from '../images/loginbutton.png';
import './login.scss';
import '../reset.css';
import Qs from 'qs';
import ReactDom from 'react-dom';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account: "",
            password: "",
            iframe: ""
        }
        this.local = this.local.bind(this);
        this.storage = window.localStorage;
        // this.handleChange = this.handleChange.bind(this);
        // this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        // this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
        if (this.storage.token && this.storage.user_id) {
            window.location.href = "myclass";
            return false;
        }
    }
    handleAccount(event) { this.setState({ account: event.target.value }) }
    handlePassword(event) { this.setState({ password: event.target.value }) }
    local() {
        localStorage.setItem('index', 0);
        // this.props.history.push('/myclass')
    }

    handleSubmit(event) {
        event.preventDefault();
        // const form = new FormData(event.target);
        var iframe = document.getElementById('iframe')
        axios({
            method: 'post',
            url: 'https://yoback.xzs51.com/api/user/login',
            dataType: "json",
            data: Qs.stringify({
                account: this.state.account,
                password: this.state.password
            })
        }).then(function (res) {
            const storage = window.localStorage;
            if (res.data.code == 1) {
                window.localStorage.setItem('token' , res.data.data.userinfo.token)
                storage.user_id = res.data.data.userinfo.id;//用户ID
                storage.username = res.data.data.userinfo.username;//用户手机号
                storage.nickname = res.data.data.userinfo.nickname;//昵称
                storage.avatar = res.data.data.userinfo.avatar;//头像
                console.log(res.data)
                if (storage.token) {
                    ReactDom.findDOMNode(iframe).src = "http://scratch.xzs51.com/index.html?token=" + storage.token;
                    setTimeout(function () {
                        window.location = "myclass";
                    }, 2000);
                } else {
                    alert('登录异常');
                }
            } else{
            }
            return false;
        })
    }

    render() {
        return (
            <div className="loginbox">
                <div className="Login_BOX">
                    <div className="Login_Logo">
                        <img src={YoCode} alt="" draggable="false" />
                    </div>
                    <div className="Login_Box">
                        <div className="loginlogo">
                            <img src={YOYO} alt="" draggable="false" />
                        </div>
                        <div className="Login_box">
                            <div className="logininput">
                                <p>游研视界</p>
                                <form
                                    onSubmit={this.handleSubmit.bind(this)}
                                >
                                    <input type="text" name="account" placeholder="请输入您的手机号" className="input-1" maxLength="11" onChange={this.handleAccount.bind(this)} value={this.state.account} />
                                    {/* <br /> */}
                                    <input type="text" name="password" placeholder="请输入您的密码" className="input-2" onChange={this.handlePassword.bind(this)} value={this.state.password} />
                                    {/* <br /> */}
                                    <button onClick={this.local}
                                        type="submit"
                                        variant="contained"
                                        className="Loginin"
                                    >
                                        <img src={LoginButton} alt="" draggable="false" />
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <iframe id="iframe" src={this.state.iframe} frameBorder="0" title="navigation"></iframe>
            </div>
        )
    }
}



export default Login;