import React from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import Qs from 'qs';
import PropTypes from 'prop-types';
// import Linkly from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Img from '../images/yocode.png';
import LearningAbility from '../images/learning_ability.png';
import MiningTalent from '../images/mining_talent.png';
import KnowFuture from '../images/knowfuture.png';
import Arts from '../images/arts.png';
import Mathc from '../images/math.png';
import Livec from '../images/live.png';
import Smart from '../images/smart.png';
import Interaction from '../images/interaction.png';
import Howtosee from '../images/howtosee.png';
import Preparation from '../images/preparation.png';
import Handsup from '../images/handsup.png';
import BoyAndGirl from '../images/boyandgirl.png';
import BannerIMG from '../images/banner.png';
import ClosePopup from '../images/closePopup.png';
import YOYOQrcode from '../images/yoyoqrcode.png';
import Quest from '../images/questget.png';
import FiveTeach from '../images/fiveteach.png';
import History from '../images/history.png';
import Physics from '../images/physics.png';
import Astronomy from '../images/astronomy.png';
import Geography from '../images/geography.png';
import Objectives from '../images/home_objectives.png';
import Stars from '../images/Stars.png';
import RightTop from '../images/righttop.png';
import E_series from '../images/Eseries.png';
import S_series from '../images/Sseries.png';
import P_series from '../images/Pseries.png';
import M_series from '../images/Mseries.png';
import U_series from '../images/Useries.png';
import Erweima from '../images/erweima.png';
import DOWN from '../images/down.png';
import CompanyLeft from '../images/company_left.png';
import CompanyRight from '../images/company_right.png';
import Experience from '../images/experience.gif';
// import Linkly from '@material-ui/core/Link';
import '../lunbo/PlayImage.scss';
import './header.scss';
import './bottomfoot.scss';
import './home.scss';
import './curriculum.scss';
import './teachingmode.scss';
import './process.scss';
import '../reset.css';
import './suspension.scss';
import './popup.scss';
import './successpopup.scss';
import LunBo from './lunbo';
// import Modal from './modal'

function HomeTitle() {

  const Immediately = () => {
    var phone = document.getElementById('phone').value;
    if ((/^1[34578]\d{9}$/.test(phone))) {
      document.getElementById('errorformat').innerHTML = '';
      document.getElementById('Popupwindow').style.display = 'flex';
      document.getElementById('popup_tele').value = phone
    } else {
      document.getElementById('errorformat').innerHTML = '格式错误'
      return false;
    }
  }

  const HomeTuWen_1 = () => {
    document.getElementById('Home_TuWen_1').style.transform = 'scale(1.05 ,1.05)'
  }

  const homeTuWen_1 = () => {
    document.getElementById('Home_TuWen_1').style.transform = 'scale(1,1)'

  }
  
  const HomeTuWen_2 = () => {
    document.getElementById('Home_TuWen_2').style.transform = 'scale(1.05 ,1.05)'
  }

  const homeTuWen_2 = () => {
    document.getElementById('Home_TuWen_2').style.transform = 'scale(1,1)'

  }
  
  const HomeTuWen_4 = () => {
    document.getElementById('Home_TuWen_4').style.transform = 'scale(1.05 ,1.05)'
  }

  const homeTuWen_4 = () => {
    document.getElementById('Home_TuWen_4').style.transform = 'scale(1,1)'
  }

  const Explanation_1 = () => {
    document.getElementById('home_explanation_one').style.display = 'block'
  }

  const Explanation_2 = () => {
    document.getElementById('home_explanation_two').style.display = 'block'
  }

  const Explanation_3 = () => {
    document.getElementById('home_explanation_three').style.display = 'block'
  }

  const Explanation_4 = () => {
    document.getElementById('home_explanation_four').style.display = 'block'
  }

  const Explanation_5 = () => {
    document.getElementById('home_explanation_five').style.display = 'block'
  }

  const Explanation_6 = () => {
    document.getElementById('home_explanation_six').style.display = 'block'
  }

  const Explanation = () => {
    document.getElementById('home_explanation_one').style.display = 'none'
    document.getElementById('home_explanation_two').style.display = 'none'
    document.getElementById('home_explanation_three').style.display = 'none'
    document.getElementById('home_explanation_four').style.display = 'none'
    document.getElementById('home_explanation_five').style.display = 'none'
    document.getElementById('home_explanation_six').style.display = 'none'
  }

  const Home_series_1 = () => {
    document.getElementById('Home_Series_1').style.background = '#F59F1A'
    document.getElementById('HomeSeries_1').style.color = '#FFFFFF'
    document.getElementById('HomeSeries_1').style.fontWeight = 'bold'
    document.getElementById('Home_Series_one').style.display = 'block'
    document.getElementById('Home_Series_2').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_2').style.color = '#333333'
    document.getElementById('HomeSeries_2').style.fontWeight = '500'
    document.getElementById('Home_Series_two').style.display = 'none'
    document.getElementById('Home_Series_3').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_3').style.color = '#333333'
    document.getElementById('HomeSeries_3').style.fontWeight = '500'
    document.getElementById('Home_Series_three').style.display = 'none'
    document.getElementById('Home_Series_4').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_4').style.color = '#333333'
    document.getElementById('HomeSeries_4').style.fontWeight = '500'
    document.getElementById('Home_Series_four').style.display = 'none'
    document.getElementById('Home_Series_5').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_5').style.color = '#333333'
    document.getElementById('HomeSeries_5').style.fontWeight = '500'
    document.getElementById('Home_Series_five').style.display = 'none'
  }

  const Home_series_2 = () => {
    document.getElementById('Home_Series_2').style.background = '#F59F1A'
    document.getElementById('HomeSeries_2').style.color = '#FFFFFF'
    document.getElementById('HomeSeries_2').style.fontWeight = 'bold'
    document.getElementById('Home_Series_two').style.display = 'block'
    document.getElementById('Home_Series_1').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_1').style.color = '#333333'
    document.getElementById('HomeSeries_1').style.fontWeight = '500'
    document.getElementById('Home_Series_one').style.display = 'none'
    document.getElementById('Home_Series_3').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_3').style.color = '#333333'
    document.getElementById('HomeSeries_3').style.fontWeight = '500'
    document.getElementById('Home_Series_three').style.display = 'none'
    document.getElementById('Home_Series_4').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_4').style.color = '#333333'
    document.getElementById('HomeSeries_4').style.fontWeight = '500'
    document.getElementById('Home_Series_four').style.display = 'none'
    document.getElementById('Home_Series_5').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_5').style.color = '#333333'
    document.getElementById('HomeSeries_5').style.fontWeight = '500'
    document.getElementById('Home_Series_five').style.display = 'none'
  }
  
  const Home_series_3 = () => {
    document.getElementById('Home_Series_3').style.background = '#F59F1A'
    document.getElementById('HomeSeries_3').style.color = '#FFFFFF'
    document.getElementById('HomeSeries_3').style.fontWeight = 'bold'
    document.getElementById('Home_Series_three').style.display = 'block'
    document.getElementById('Home_Series_2').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_2').style.color = '#333333'
    document.getElementById('HomeSeries_2').style.fontWeight = '500'
    document.getElementById('Home_Series_two').style.display = 'none'
    document.getElementById('Home_Series_1').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_1').style.color = '#333333'
    document.getElementById('HomeSeries_1').style.fontWeight = '500'
    document.getElementById('Home_Series_one').style.display = 'none'
    document.getElementById('Home_Series_4').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_4').style.color = '#333333'
    document.getElementById('HomeSeries_4').style.fontWeight = '500'
    document.getElementById('Home_Series_four').style.display = 'none'
    document.getElementById('Home_Series_5').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_5').style.color = '#333333'
    document.getElementById('HomeSeries_5').style.fontWeight = '500'
    document.getElementById('Home_Series_five').style.display = 'none'
  }
  
  const Home_series_4 = () => {
    document.getElementById('Home_Series_4').style.background = '#F59F1A'
    document.getElementById('HomeSeries_4').style.color = '#FFFFFF'
    document.getElementById('HomeSeries_4').style.fontWeight = 'bold'
    document.getElementById('Home_Series_four').style.display = 'block'
    document.getElementById('Home_Series_2').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_2').style.color = '#333333'
    document.getElementById('HomeSeries_2').style.fontWeight = '500'
    document.getElementById('Home_Series_two').style.display = 'none'
    document.getElementById('Home_Series_3').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_3').style.color = '#333333'
    document.getElementById('HomeSeries_3').style.fontWeight = '500'
    document.getElementById('Home_Series_three').style.display = 'none'
    document.getElementById('Home_Series_1').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_1').style.color = '#333333'
    document.getElementById('HomeSeries_1').style.fontWeight = '500'
    document.getElementById('Home_Series_one').style.display = 'none'
    document.getElementById('Home_Series_5').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_5').style.color = '#333333'
    document.getElementById('HomeSeries_5').style.fontWeight = '500'
    document.getElementById('Home_Series_five').style.display = 'none'
  }
  
  const Home_series_5 = () => {
    document.getElementById('Home_Series_5').style.background = '#F59F1A'
    document.getElementById('HomeSeries_5').style.color = '#FFFFFF'
    document.getElementById('HomeSeries_5').style.fontWeight = 'bold'
    document.getElementById('Home_Series_five').style.display = 'block'
    document.getElementById('Home_Series_2').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_2').style.color = '#333333'
    document.getElementById('HomeSeries_2').style.fontWeight = '500'
    document.getElementById('Home_Series_two').style.display = 'none'
    document.getElementById('Home_Series_3').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_3').style.color = '#333333'
    document.getElementById('HomeSeries_3').style.fontWeight = '500'
    document.getElementById('Home_Series_three').style.display = 'none'
    document.getElementById('Home_Series_4').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_4').style.color = '#333333'
    document.getElementById('HomeSeries_4').style.fontWeight = '500'
    document.getElementById('Home_Series_four').style.display = 'none'
    document.getElementById('Home_Series_1').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_1').style.color = '#333333'
    document.getElementById('HomeSeries_1').style.fontWeight = '500'
    document.getElementById('Home_Series_one').style.display = 'none'
  }

  const home_problem_one = () => {
    document.getElementById('Home_problem_1').style.transform = 'rotate(180deg)'
    document.getElementById('Home_answer_1').style.display = 'block'
  }

  const home_problem_two = () => {
    document.getElementById('Home_problem_2').style.transform = 'rotate(180deg)'
    document.getElementById('Home_answer_2').style.display = 'block'
  }

  const home_problem_three = () => {
    document.getElementById('Home_problem_3').style.transform = 'rotate(180deg)'
    document.getElementById('Home_answer_3').style.display = 'block'
  }

  const Home_problem = () => {
    document.getElementById('Home_problem_1').style.transform = 'rotate(0deg)'
    document.getElementById('Home_answer_1').style.display = 'none'
    document.getElementById('Home_problem_2').style.transform = 'rotate(0deg)'
    document.getElementById('Home_answer_2').style.display = 'none'
    document.getElementById('Home_problem_3').style.transform = 'rotate(0deg)'
    document.getElementById('Home_answer_3').style.display = 'none'
  }

  return (
    <div className="hometitle">
      <div className="banner">
        <div className="bannerimg">
          <img src={BannerIMG} alt="" draggable='false' />
        </div>
        <div className="banner_free">
          <div className="banner_registered">
            <p className="bannerFree">注册领取</p>
            <p className="banner_valueOF">价值<span>399</span>元试听课</p>
            <div className="banner_input">
              <input id="phone" type="text" maxLength="11" placeholder="请输入手机号" />
              <span id="errorformat"></span>
            </div>
            <div className="banner_button" onClick={Immediately}>
              <img src={Quest} alt="" draggable='false' />
            </div>
          </div>
        </div>
      </div>
      <div className="results">
        <p className="childResult">学编程，能带给孩子什么改变?</p>
        <p className="eleYOYO">从兴趣出发启蒙思维  在游戏中发掘创造力  探索学科融合的奥秘</p>
        <div className="home_children">
          <div id="Home_TuWen_1" className="home_TuWen" onMouseOver={HomeTuWen_1} onMouseOut={homeTuWen_1} >
            <img src={LearningAbility} alt="" draggable='false' />
            <p className="home_Wenzi">用编程激发学习力</p>
            <p className="home_XWenzi">紧扣小初高课程设计<br/>兴趣启迪数理思维</p>
          </div>
          <div id="Home_TuWen_2" className="home_TuWen" onMouseOver={HomeTuWen_2} onMouseOut={homeTuWen_2} >
            <img src={MiningTalent} alt="" draggable='false' />
            <p className="home_Wenzi">用编程挖掘天赋</p>
            <p className="home_XWenzi">利用编程的开放性思维<br/>开发孩子更多潜能</p>
          </div>
          <div id="Home_TuWen_4" className="home_TuWen" onMouseOver={HomeTuWen_4} onMouseOut={homeTuWen_4} >
            <img src={KnowFuture} alt="" draggable='false' />
            <p className="home_Wenzi">用编程了解未来</p>
            <p className="home_XWenzi">英语是现在的通用语言<br/>编程是未来的通用语言</p>
          </div>
        </div>
      </div>
      <div className="fuse">
        <p className="childResult">多学科融合 让孩子学到的远超过编程</p>
        <p className="eleYOYO">YO小程希望每一个孩子都能成为未来的创造者</p>
        <div className="home_fuse">
          <div className="home_video">
            <video id="home_myVideo" src='http://yoback.xzs51.com/uploads/20200904/d5b720d4c0797daef2f77de866ac0515.mp4' type="video/mp4" controls autoplay loop controlsList="nodownload" onContextMenu="return false"></video>
          </div>
          <div className="home_introduce">
            <h4>
              与小象Yoyo一起<br/>在编程的世界成为最牛的编程师
            </h4>
            <p>
              深入海底两万里与鲨鱼一起保护亚特兰蒂斯最后的太阳宫；<br/>化身星际小队的队长，带领星际小队保卫人类与地球；<br/>带领小伙伴前往地心挑战世界，与霸王龙一起穿梭于雨林之中；<br/>还可以做编程的创世神，制造火山、洪水、科技来改变世界。
            </p>
          </div>
        </div>
        <div className="home_subject">
          <div className="home_Sub">
            <div>
              <img src={Mathc} alt="" draggable='false'/>
            </div>
            <p>数学</p>
          </div>
          <div className="home_Sub">
            <div>
              <img src={Physics} alt="" draggable='false' />
            </div>
            <p>物理</p>
          </div>
          <div className="home_Sub">
            <div>
              <img src={Arts} alt="" draggable='false' />
            </div>
            <p>艺术</p>
          </div>
          <div className="home_Sub">
            <div>
              <img src={Geography} alt="" draggable='false' />
            </div>
            <p>地理</p>
          </div>
          <div className="home_Sub">
            <div>
              <img src={Astronomy} alt="" draggable='false' />
            </div>
            <p>天文</p>
          </div>
          <div>
            <div>
              <img src={History} alt="" draggable='false' />
            </div>
            <p>历史</p>
          </div>
        </div>
      </div>
      <div className="home_advanced"> 
        <p className="childResult">CSM进阶思维教学目标</p>
        <p className="home_eleYOYO">同步国际公认 K12 阶段 《计算机科学教师协会标准》（CSTA）引导孩子发散思维 遵循《麻省理工创造性学习螺旋》</p>
        <p className="home_eleyoyo">注重孩子实际应用和个性化需求 结合教育部颁布《信息技术课程标准》</p>
        <div className="home_objective">
          <div className="home_step">
            <img src={Objectives} alt="" draggable='false' />
            <div className="home_step_1" onMouseOver={Explanation_1} onMouseOut={Explanation}>
              <div className="home_step_one">
                <h3>1</h3>
                <p>解构能力</p>
              </div>
            </div>
            <div className="home_step_2" onMouseOver={Explanation_2} onMouseOut={Explanation}>
              <div className="home_step_two">
                <h3>2</h3>
                <p>算法能力</p>
              </div>
            </div>
            <div className="home_step_3" onMouseOver={Explanation_3} onMouseOut={Explanation}>
              <div className="home_step_three">
                <h3>3</h3>
                <p>沟通协作</p>
              </div>
            </div>
            <div className="home_step_4" onMouseOver={Explanation_4} onMouseOut={Explanation}>
              <div className="home_step_four">
                <h3>4</h3>
                <p>创意能力</p>
              </div>
            </div>
            <div className="home_step_5" onMouseOver={Explanation_5} onMouseOut={Explanation}>
              <div className="home_step_five">
                <h3>5</h3>
                <p>抽象能力</p>
              </div>
            </div>
            <div className="home_step_6" onMouseOver={Explanation_6} onMouseOut={Explanation}>
              <div className="home_step_six">
                <h3>6</h3>
                <p>模式提取</p>
              </div>
            </div>
            <div id="home_explanation_one" className="home_explanation_1" >
              <div className="home_explanation_jian"></div>
              <p>学会有效、有序地解构复杂的问题，将大问题拆解成小问题，最终逆向建构结构类知识和过程类知识</p>
            </div>
            <div id="home_explanation_two" className="home_explanation_2">
              <div className="home_explanation_jian"></div>
              <p>算法能力是编程和数学课程学习能力的综合表现，掌握了它就掌握了打开数理思维的启蒙大门，它由阅读算法能力、提炼算理能力、算法语言能力、应用算法能力四部分构成</p>
            </div>
            <div id="home_explanation_three" className="home_explanation_3">
              <div className="home_explanation_jian"></div>
              <p>和其他小工程师共同完成大型编程作品，发挥每个成员不同位置上的最大作用，让团队优势翻倍增长</p>
            </div>
            <div id="home_explanation_four" className="home_explanation_4">
              <div className="home_explanation_jian"></div>
              <p>玩出未来创意能力，经过充分系统的比较和可靠预测，灵活调整程序形态，从你的键盘下诞生更多创意作品</p>
            </div>
            <div id="home_explanation_five" className="home_explanation_5">
              <div className="home_explanation_jian"></div>
              <p>把具体变成概念，进而驾驭概念进行思考，从复杂的逻辑步骤中抓住事物的本质，让程序变得简单和有趣</p>
            </div>
            <div id="home_explanation_six" className="home_explanation_6">
              <div className="home_explanation_jian"></div>
              <p>解决抽象形式的分类问题，使用机器的思维方式解决计算机的问题，踏入人工智能的第一步</p>
            </div>
          </div>
        </div>
      </div>
      <div className="home_class_system">
        <p className="childResult">ESPM+多元思维课程体系</p>
        <p className="eleYOYO">思维启蒙-熟练应用-逻辑强化-创意创造-视野开拓</p>
        <div className="home_series">
          <div id="Home_Series_1" className="home_series_1" onClick={Home_series_1}><p  id="HomeSeries_1">E系列-Enlighten</p></div>
          <div id="Home_Series_2" className="home_series_2" onClick={Home_series_2}><p id="HomeSeries_2">S系列-Systemetic</p></div>
          <div id="Home_Series_3" className="home_series_3" onClick={Home_series_3}><p id="HomeSeries_3">P系列-Proficient</p></div>
          <div id="Home_Series_4" className="home_series_4" onClick={Home_series_4}><p id="HomeSeries_4">M系列-Maker</p></div>
          <div id="Home_Series_5" className="home_series_5" onClick={Home_series_5}><p id="HomeSeries_5">U系列-Unique</p></div>
        </div>
      </div>
      <div id="Home_Series_one" className="home_series_one">
        <div className="home_series_jian"></div>
        <div className="home_series_first">
          <div className="home_series_img_1">
            <img src={E_series} alt="" draggable='false' />
          </div>
          <div className="home_series_img_2">
            <h3>Scratch 图形化编程</h3>
            <img className="home_series_img_3" src={RightTop} alt="" draggable='false' />
            <p className="home_series_p_1">思维启蒙 潜力激发<br/>像玩游戏一样学编程<br/>快乐完成编程基础知识搭建，寓教于乐中动手完成简单程序的制作</p>
            <div className="home_series_img_4"><p>适合 6-11岁</p></div>
            <div className="home_series_img_5">
              <img src={Stars} alt="" draggable='false' />
              <span>课程知识点：天文、地理、数学、物理、艺术、英语</span>
            </div>
            <div className="home_series_img_6">
              <img src={Stars} alt="" draggable='false' />
              <span>跨学科知识点：紧贴小学阶段K12课程，融合1200个小学阶段<p>课程知识点</p></span>
            </div>
          </div>
        </div>
      </div>
      <div id="Home_Series_two" className="home_series_two">
        <div className="home_series_jian"></div>
        <div className="home_series_first">
          <div className="home_series_img_1">
            <img src={S_series} alt="" draggable='false' />
          </div>
          <div className="home_series_img_2">
            <h3>Scratch 高级图形化编程</h3>
            <img className="home_series_img_3" src={RightTop} alt="" draggable='false' />
            <p className="home_series_p_1">系统思维 熟练应用<br/>逐步实现与现实应用的无缝对接<br/>算法和数据结构的学习，最后综合应用所学知识</p>
            <div className="home_series_img_4"><p>适合 8岁以上</p></div>
            <div className="home_series_img_5">
              <img src={Stars} alt="" draggable='false' />
              <span>课程知识点：天文、地理、数学、物理、艺术、英语</span>
            </div>
            <div className="home_series_img_6">
              <img src={Stars} alt="" draggable='false' />
              <span>制造项目：贪吃蛇、超级玛丽、愤怒的老鼠等综合游戏设计</span>
            </div>
          </div>
        </div>
      </div>
      <div id="Home_Series_three" className="home_series_three">
        <div className="home_series_jian"></div>
        <div className="home_series_first">
          <div className="home_series_img_1">
            <img src={P_series} alt="" draggable='false' />
          </div>
          <div className="home_series_img_2">
            <h3>Python 代码编程课</h3>
            <img className="home_series_img_3" src={RightTop} alt="" draggable='false' />
            <p className="home_series_p_1">逻辑强化 融会贯通<br/>北京、浙江、山东已将编程列入中高考科目<br/>认证赛事通用编程语言，孩子学习就是在比赛</p>
            <div className="home_series_img_4"><p>适合 9岁以上</p></div>
            <div className="home_series_img_5">
              <img src={Stars} alt="" draggable='false' />
              <span>课程知识点：循环、判断等常用语法，数据结构、函数等知识</span>
            </div>
            <div className="home_series_img_6">
              <img src={Stars} alt="" draggable='false' />
              <span>制造项目：AI 机器人、开发语音管家、人脸识别、智能导航</span>
            </div>
          </div>
        </div>
      </div>
      <div id="Home_Series_four" className="home_series_four">
        <div className="home_series_jian"></div>
        <div className="home_series_first">
          <div className="home_series_img_1">
            <img src={M_series} alt="" draggable='false' />
          </div>
          <div className="home_series_img_2">
            <h3>Micro: Bit 迈酷硬件课</h3>
            <img className="home_series_img_3" src={RightTop} alt="" draggable='false' />
            <p className="home_series_p_1">创意创造 触摸未来<br/>着眼科创能力的提升与拓展编程外延，将学到的编程知识，系统<br/>应用到 micro:bit硬件硬件组装+前沿科技模拟+学科知识融入</p>
            <div className="home_series_img_4"><p>适合 10岁以上</p></div>
            <div className="home_series_img_5">
              <img src={Stars} alt="" draggable='false' />
              <span>课程知识点：物联网知识接触、人工智能、航天航空、物理机<p>械、生物化学</p></span>
            </div>
            <div className="home_series_img_6">
              <img src={Stars} alt="" draggable='false' />
              <span>制造项目：智能家居、智慧城市系统</span>
            </div>
          </div>
        </div>
      </div>
      <div id="Home_Series_five" className="home_series_five">
        <div className="home_series_jian"></div>
        <div className="home_series_first">
          <div className="home_series_img_1">
            <img src={U_series} alt="" draggable='false' />
          </div>
          <div className="home_series_img_2">
            <h3>U系列-Unique</h3>
            <div className="home_series_img_4"><p>面向国际 视野开拓</p></div>
            <img className="home_series_img_3" src={RightTop} alt="" draggable='false' />
            <p className="home_series_p_1">U 系列课程，发现每个孩子独一无二的闪光点<br/>U系列，YO小程特别系列课程，接轨国际通用PBL项目制课程模<br/>式，每阶段课程会从一个实际问题出发， 编程+兴趣，针对孩子不<br/>同阶段的年龄、特长、爱好进行设计。</p>
            <div className="home_series_img_5">
              <img src={Stars} alt="" draggable='false' />
              <span>名师1v1国际赛事营</span>
            </div>
            <div className="home_series_img_6">
              <img src={Stars} alt="" draggable='false' />
              <span>“创”艺术编程营</span>
            </div>
            <div className="home_series_img_6">
              <img src={Stars} alt="" draggable='false' />
              <span>新奇“造物”编程营</span>
            </div>
          </div>
        </div>
      </div>
      <div className="teacherteam">
        <p className="childResult">教师团队</p>
        <p className="eleYOYO">大咖级名师团队坐镇教学 联合北大、北师大名师研发课程</p>
        <div className="TeacherT">
          <LunBo/>
        </div>
      </div>
      <div className="home_teachmode">
        <p className="childResult">教学模式</p>
        <p className="eleYOYO">YO小程多场景课堂</p>
        <div className="teachWay">
          <div className="way">
            <img src={Livec} alt="" draggable='false' />
            <h4>学霸直播课堂</h4>
            <p>面对面指导教学，再也不用担心辅导孩子作业</p>
          </div>
          <div className="way home_way">
            <img src={Smart} alt="" draggable='false' />
            <h4>AI动画录播课堂</h4>
            <p>课程可反复观看，可根据需求随时随地听课</p>
          </div>
          <div className="way">
            <img src={Interaction} alt="" draggable='false' />
            <h4>社群互动课堂</h4>
            <p>开学典礼、编程知识讲座毕业颁奖典礼都将在这里举行</p>
          </div>
        </div>
      </div>
      <div className="home_five">
        <p className="childResult">FIVE教学法</p>
        <p className="eleYOYO">“学、练、测、赛、创”</p>
        <div className="home_FiveTeach">
          <img src={FiveTeach} alt="" draggable='false' />
        </div>
      </div>
      <div className="aboutyoyo">
        <p className="childResult">关于YO小程</p>
        <p className="eleYOYO">为青少年打造轻松愉悦的在线学习空间</p>
        <div className="AboutYOYO">
          <div className="BoyAGirl">
            <img src={BoyAndGirl} alt="" draggable='false' />
          </div>
          <div className="boyANDgirl">
            <p className="home_boy">YO小程，是一款充满乐趣与欢乐的在线少儿编程课程，这里集结了全世界近100万的孩子在线共同学习，北大、北师大名师独家研发的优质编程课程等你来体验全新矩阵式课程体系，开放自由的学习环境、超乎想象的课堂全场景打造，加载丰富的动画特效，在乐趣中吸收多种学科知识、提升编程思维，为青少年打造轻松愉快的在线学习空间。</p>
            <p className="home_girl">快拉上小伙伴一同加入YOYO冒险大家庭，开启编程世界的大门，创造属于你们的未来世界！</p>
          </div>
        </div>
      </div>
      <div className="home_number">
        <div className="home_chance">
          <h3>21<span>个</span></h3>
          <p>21个省份孩子共同选择</p>
        </div>
        <div className="home_chance home_chance_1">
          <h3>10<span>万</span></h3>
          <p>10万+高知家庭父母选择</p>
        </div>
        <div className="home_chance">
          <h3>100<span>家</span></h3>
          <p>100+知名中小学选择</p>
        </div>
      </div>
      <div className="home_problem">
        <p className="childResult">常见问题</p>
        <div className="home_problem_1" onMouseOver={home_problem_one} onMouseOut={Home_problem}>
          <p>1. 上课支持工具？</p>
          <div className="home_problem_img">
            <img id="Home_problem_1" src={DOWN} alt="" draggable='false' />
          </div>
        </div>
        <div id="Home_answer_1" className="home_answer_1">
          <div className="home_answer_jian"></div>
          <p>学生课：支持Win7级以上系统、苹果系统上课<br/>家长课：支持手机上课</p>
        </div>
        <div className="home_problem_2" onMouseOver={home_problem_two} onMouseOut={Home_problem}>
          <p>2. 孩子学校学过一点，从入门级别学习合适吗？</p>
          <div className="home_problem_img">
            <img id="Home_problem_2" src={DOWN} alt="" draggable='false' />
          </div>
        </div>
        <div id="Home_answer_2" className="home_answer_2">
          <div className="home_answer_jian"></div>
          <p>绝大多数在学校学Scratch课程的内容都比较基础，相当于我们前三课的水平，建议比较系统的去学习我们的课程。</p>
        </div>
        <div className="home_problem_3" onMouseOver={home_problem_three} onMouseOut={Home_problem}>
          <p>3. 家长需要参与课程吗？</p>
          <div className="home_problem_img">
            <img id="Home_problem_3" src={DOWN} alt="" draggable='false' />
          </div>
        </div>
        <div id="Home_answer_3" className="home_answer_3">
          <div className="home_answer_jian"></div>
          <p>大部分时间，家长不用参与到课程中。前1-2次课程，家长需要配合做一些电脑上的操作和设置。孩子完成作业后，家长可以在公众号上收到老师的点评。</p>
        </div>
      </div>
      <div className="home_add">
        <div className="home_addTeacher">
          <div className="home_add_WeiXin">
            <h3>添加“卡卡老师“微信，获得价值<span>399</span>元编程课</h3>
            <p>7*24小时在线贴心服务的卡卡老师，会随时解答您的疑问，而且他<br/>还会不定时发送优惠活动哦！</p>
          </div>
          <div className="home_add_code">
            <img src={Erweima} alt="" draggable='false' />
          </div>
        </div>
      </div>
    </div>
  )
}

// 课程体系
function CurriculumC() {
  const Immediately = () => {
    var phone = document.getElementById('PHONE').value;
    if ((/^1[34578]\d{9}$/.test(phone))) {
      document.getElementById('errorformat').innerHTML = '';
      document.getElementById('Popupwindow').style.display = 'flex';
      document.getElementById('popup_tele').value = phone
    } else {
      document.getElementById('errorformat').innerHTML = '格式错误'
      return false;
    }
  }
  const Home_series_1 = () => {
    document.getElementById('Home_Series_1').style.background = '#F59F1A'
    document.getElementById('HomeSeries_1').style.color = '#FFFFFF'
    document.getElementById('HomeSeries_1').style.fontWeight = 'bold'
    document.getElementById('Home_Series_one').style.display = 'block'
    document.getElementById('Home_Series_2').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_2').style.color = '#333333'
    document.getElementById('HomeSeries_2').style.fontWeight = '500'
    document.getElementById('Home_Series_two').style.display = 'none'
    document.getElementById('Home_Series_3').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_3').style.color = '#333333'
    document.getElementById('HomeSeries_3').style.fontWeight = '500'
    document.getElementById('Home_Series_three').style.display = 'none'
    document.getElementById('Home_Series_4').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_4').style.color = '#333333'
    document.getElementById('HomeSeries_4').style.fontWeight = '500'
    document.getElementById('Home_Series_four').style.display = 'none'
    document.getElementById('Home_Series_5').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_5').style.color = '#333333'
    document.getElementById('HomeSeries_5').style.fontWeight = '500'
    document.getElementById('Home_Series_five').style.display = 'none'
  }

  const Home_series_2 = () => {
    document.getElementById('Home_Series_2').style.background = '#F59F1A'
    document.getElementById('HomeSeries_2').style.color = '#FFFFFF'
    document.getElementById('HomeSeries_2').style.fontWeight = 'bold'
    document.getElementById('Home_Series_two').style.display = 'block'
    document.getElementById('Home_Series_1').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_1').style.color = '#333333'
    document.getElementById('HomeSeries_1').style.fontWeight = '500'
    document.getElementById('Home_Series_one').style.display = 'none'
    document.getElementById('Home_Series_3').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_3').style.color = '#333333'
    document.getElementById('HomeSeries_3').style.fontWeight = '500'
    document.getElementById('Home_Series_three').style.display = 'none'
    document.getElementById('Home_Series_4').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_4').style.color = '#333333'
    document.getElementById('HomeSeries_4').style.fontWeight = '500'
    document.getElementById('Home_Series_four').style.display = 'none'
    document.getElementById('Home_Series_5').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_5').style.color = '#333333'
    document.getElementById('HomeSeries_5').style.fontWeight = '500'
    document.getElementById('Home_Series_five').style.display = 'none'
  }
  
  const Home_series_3 = () => {
    document.getElementById('Home_Series_3').style.background = '#F59F1A'
    document.getElementById('HomeSeries_3').style.color = '#FFFFFF'
    document.getElementById('HomeSeries_3').style.fontWeight = 'bold'
    document.getElementById('Home_Series_three').style.display = 'block'
    document.getElementById('Home_Series_2').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_2').style.color = '#333333'
    document.getElementById('HomeSeries_2').style.fontWeight = '500'
    document.getElementById('Home_Series_two').style.display = 'none'
    document.getElementById('Home_Series_1').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_1').style.color = '#333333'
    document.getElementById('HomeSeries_1').style.fontWeight = '500'
    document.getElementById('Home_Series_one').style.display = 'none'
    document.getElementById('Home_Series_4').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_4').style.color = '#333333'
    document.getElementById('HomeSeries_4').style.fontWeight = '500'
    document.getElementById('Home_Series_four').style.display = 'none'
    document.getElementById('Home_Series_5').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_5').style.color = '#333333'
    document.getElementById('HomeSeries_5').style.fontWeight = '500'
    document.getElementById('Home_Series_five').style.display = 'none'
  }
  
  const Home_series_4 = () => {
    document.getElementById('Home_Series_4').style.background = '#F59F1A'
    document.getElementById('HomeSeries_4').style.color = '#FFFFFF'
    document.getElementById('HomeSeries_4').style.fontWeight = 'bold'
    document.getElementById('Home_Series_four').style.display = 'block'
    document.getElementById('Home_Series_2').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_2').style.color = '#333333'
    document.getElementById('HomeSeries_2').style.fontWeight = '500'
    document.getElementById('Home_Series_two').style.display = 'none'
    document.getElementById('Home_Series_3').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_3').style.color = '#333333'
    document.getElementById('HomeSeries_3').style.fontWeight = '500'
    document.getElementById('Home_Series_three').style.display = 'none'
    document.getElementById('Home_Series_1').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_1').style.color = '#333333'
    document.getElementById('HomeSeries_1').style.fontWeight = '500'
    document.getElementById('Home_Series_one').style.display = 'none'
    document.getElementById('Home_Series_5').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_5').style.color = '#333333'
    document.getElementById('HomeSeries_5').style.fontWeight = '500'
    document.getElementById('Home_Series_five').style.display = 'none'
  }
  
  const Home_series_5 = () => {
    document.getElementById('Home_Series_5').style.background = '#F59F1A'
    document.getElementById('HomeSeries_5').style.color = '#FFFFFF'
    document.getElementById('HomeSeries_5').style.fontWeight = 'bold'
    document.getElementById('Home_Series_five').style.display = 'block'
    document.getElementById('Home_Series_2').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_2').style.color = '#333333'
    document.getElementById('HomeSeries_2').style.fontWeight = '500'
    document.getElementById('Home_Series_two').style.display = 'none'
    document.getElementById('Home_Series_3').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_3').style.color = '#333333'
    document.getElementById('HomeSeries_3').style.fontWeight = '500'
    document.getElementById('Home_Series_three').style.display = 'none'
    document.getElementById('Home_Series_4').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_4').style.color = '#333333'
    document.getElementById('HomeSeries_4').style.fontWeight = '500'
    document.getElementById('Home_Series_four').style.display = 'none'
    document.getElementById('Home_Series_1').style.background = '#EEEEEE'
    document.getElementById('HomeSeries_1').style.color = '#333333'
    document.getElementById('HomeSeries_1').style.fontWeight = '500'
    document.getElementById('Home_Series_one').style.display = 'none'
  }

  const Explanation_1 = () => {
    document.getElementById('home_explanation_one').style.display = 'block'
  }

  const Explanation_2 = () => {
    document.getElementById('home_explanation_two').style.display = 'block'
  }

  const Explanation_3 = () => {
    document.getElementById('home_explanation_three').style.display = 'block'
  }

  const Explanation_4 = () => {
    document.getElementById('home_explanation_four').style.display = 'block'
  }

  const Explanation_5 = () => {
    document.getElementById('home_explanation_five').style.display = 'block'
  }

  const Explanation_6 = () => {
    document.getElementById('home_explanation_six').style.display = 'block'
  }

  const Explanation = () => {
    document.getElementById('home_explanation_one').style.display = 'none'
    document.getElementById('home_explanation_two').style.display = 'none'
    document.getElementById('home_explanation_three').style.display = 'none'
    document.getElementById('home_explanation_four').style.display = 'none'
    document.getElementById('home_explanation_five').style.display = 'none'
    document.getElementById('home_explanation_six').style.display = 'none'
  }

  return (
    <div className="curriculum">
      <div className="banner">
        <div className="bannerimg">
          <img src={BannerIMG} alt="" draggable='false' />
        </div>
        <div className="banner_free">
          <div className="banner_registered">
            <p className="bannerFree">注册领取</p>
            <p className="banner_valueOF">价值<span>399</span>元试听课</p>
            <div className="banner_input">
              <input id="PHONE" type="text" maxLength="11" placeholder="请输入手机号" />
              <span id="errorformat"></span>
            </div>
            <div className="banner_button" onClick={Immediately}>
              <img src={Quest} alt="" draggable='false' />
            </div>
          </div>
        </div>
      </div>
      <div className="home_class_system">
        <p className="childResult">ESPM+多元思维课程体系</p>
        <p className="eleYOYO">思维启蒙-熟练应用-逻辑强化-创意创造-视野开拓</p>
        <div className="home_series">
          <div id="Home_Series_1" className="home_series_1" onClick={Home_series_1}><p  id="HomeSeries_1">E系列-Enlighten</p></div>
          <div id="Home_Series_2" className="home_series_2" onClick={Home_series_2}><p id="HomeSeries_2">S系列-Systemetic</p></div>
          <div id="Home_Series_3" className="home_series_3" onClick={Home_series_3}><p id="HomeSeries_3">P系列-Proficient</p></div>
          <div id="Home_Series_4" className="home_series_4" onClick={Home_series_4}><p id="HomeSeries_4">M系列-Maker</p></div>
          <div id="Home_Series_5" className="home_series_5" onClick={Home_series_5}><p id="HomeSeries_5">U系列-Unique</p></div>
        </div>
      </div>
      <div id="Home_Series_one" className="home_series_one">
        <div className="home_series_jian"></div>
        <div className="home_series_first">
          <div className="home_series_img_1">
            <img src={E_series} alt="" draggable='false' />
          </div>
          <div className="home_series_img_2">
            <h3>Scratch 图形化编程</h3>
            <img className="home_series_img_3" src={RightTop} alt="" draggable='false' />
            <p className="home_series_p_1">思维启蒙 潜力激发<br/>像玩游戏一样学编程<br/>快乐完成编程基础知识搭建，寓教于乐中动手完成简单程序的制作</p>
            <div className="home_series_img_4"><p>适合 6-11岁</p></div>
            <div className="home_series_img_5">
              <img src={Stars} alt="" draggable='false' />
              <span>课程知识点：天文、地理、数学、物理、艺术、英语</span>
            </div>
            <div className="home_series_img_6">
              <img src={Stars} alt="" draggable='false' />
              <span>跨学科知识点：紧贴小学阶段K12课程，融合1200个小学阶段<p>课程知识点</p></span>
            </div>
          </div>
        </div>
      </div>
      <div id="Home_Series_two" className="home_series_two">
        <div className="home_series_jian"></div>
        <div className="home_series_first">
          <div className="home_series_img_1">
            <img src={S_series} alt="" draggable='false' />
          </div>
          <div className="home_series_img_2">
            <h3>Scratch 高级图形化编程</h3>
            <img className="home_series_img_3" src={RightTop} alt="" draggable='false' />
            <p className="home_series_p_1">系统思维 熟练应用<br/>逐步实现与现实应用的无缝对接<br/>算法和数据结构的学习，最后综合应用所学知识</p>
            <div className="home_series_img_4"><p>适合 8岁以上</p></div>
            <div className="home_series_img_5">
              <img src={Stars} alt="" draggable='false' />
              <span>课程知识点：天文、地理、数学、物理、艺术、英语</span>
            </div>
            <div className="home_series_img_6">
              <img src={Stars} alt="" draggable='false' />
              <span>制造项目：贪吃蛇、超级玛丽、愤怒的老鼠等综合游戏设计</span>
            </div>
          </div>
        </div>
      </div>
      <div id="Home_Series_three" className="home_series_three">
        <div className="home_series_jian"></div>
        <div className="home_series_first">
          <div className="home_series_img_1">
            <img src={P_series} alt="" draggable='false' />
          </div>
          <div className="home_series_img_2">
            <h3>Python 代码编程课</h3>
            <img className="home_series_img_3" src={RightTop} alt="" draggable='false' />
            <p className="home_series_p_1">逻辑强化 融会贯通<br/>北京、浙江、山东已将编程列入中高考科目<br/>认证赛事通用编程语言，孩子学习就是在比赛</p>
            <div className="home_series_img_4"><p>适合 9岁以上</p></div>
            <div className="home_series_img_5">
              <img src={Stars} alt="" draggable='false' />
              <span>课程知识点：循环、判断等常用语法，数据结构、函数等知识</span>
            </div>
            <div className="home_series_img_6">
              <img src={Stars} alt="" draggable='false' />
              <span>制造项目：AI 机器人、开发语音管家、人脸识别、智能导航</span>
            </div>
          </div>
        </div>
      </div>
      <div id="Home_Series_four" className="home_series_four">
        <div className="home_series_jian"></div>
        <div className="home_series_first">
          <div className="home_series_img_1">
            <img src={M_series} alt="" draggable='false' />
          </div>
          <div className="home_series_img_2">
            <h3>Micro: Bit 迈酷硬件课</h3>
            <img className="home_series_img_3" src={RightTop} alt="" draggable='false' />
            <p className="home_series_p_1">创意创造 触摸未来<br/>着眼科创能力的提升与拓展编程外延，将学到的编程知识，系统<br/>应用到 micro:bit硬件硬件组装+前沿科技模拟+学科知识融入</p>
            <div className="home_series_img_4"><p>适合 10岁以上</p></div>
            <div className="home_series_img_5">
              <img src={Stars} alt="" draggable='false' />
              <span>课程知识点：物联网知识接触、人工智能、航天航空、物理机<p>械、生物化学</p></span>
            </div>
            <div className="home_series_img_6">
              <img src={Stars} alt="" draggable='false' />
              <span>制造项目：智能家居、智慧城市系统</span>
            </div>
          </div>
        </div>
      </div>
      <div id="Home_Series_five" className="home_series_five">
        <div className="home_series_jian"></div>
        <div className="home_series_first">
          <div className="home_series_img_1">
            <img src={U_series} alt="" draggable='false' />
          </div>
          <div className="home_series_img_2">
            <h3>U系列-Unique</h3>
            <div className="home_series_img_4"><p>面向国际 视野开拓</p></div>
            <img className="home_series_img_3" src={RightTop} alt="" draggable='false' />
            <p className="home_series_p_1">U 系列课程，发现每个孩子独一无二的闪光点<br/>U系列，YO小程特别系列课程，接轨国际通用PBL项目制课程模<br/>式，每阶段课程会从一个实际问题出发， 编程+兴趣，针对孩子不<br/>同阶段的年龄、特长、爱好进行设计。</p>
            <div className="home_series_img_5">
              <img src={Stars} alt="" draggable='false' />
              <span>名师1v1国际赛事营</span>
            </div>
            <div className="home_series_img_6">
              <img src={Stars} alt="" draggable='false' />
              <span>“创”艺术编程营</span>
            </div>
            <div className="home_series_img_6">
              <img src={Stars} alt="" draggable='false' />
              <span>新奇“造物”编程营</span>
            </div>
          </div>
        </div>
      </div>
      <div className="home_advanced" style={{paddingBottom:'100px'}}> 
        <p className="childResult">CSM进阶思维教学目标</p>
        <p className="home_eleYOYO">同步国际公认 K12 阶段 《计算机科学教师协会标准》（CSTA）引导孩子发散思维 遵循《麻省理工创造性学习螺旋》</p>
        <p className="home_eleyoyo">注重孩子实际应用和个性化需求 结合教育部颁布《信息技术课程标准》</p>
        <div className="home_objective">
          <div className="home_step">
            <img src={Objectives} alt="" draggable='false' />
            <div className="home_step_1" onMouseOver={Explanation_1} onMouseOut={Explanation}>
              <div className="home_step_one">
                <h3>1</h3>
                <p>解构能力</p>
              </div>
            </div>
            <div className="home_step_2" onMouseOver={Explanation_2} onMouseOut={Explanation}>
              <div className="home_step_two">
                <h3>2</h3>
                <p>算法能力</p>
              </div>
            </div>
            <div className="home_step_3" onMouseOver={Explanation_3} onMouseOut={Explanation}>
              <div className="home_step_three">
                <h3>3</h3>
                <p>沟通协作</p>
              </div>
            </div>
            <div className="home_step_4" onMouseOver={Explanation_4} onMouseOut={Explanation}>
              <div className="home_step_four">
                <h3>4</h3>
                <p>创意能力</p>
              </div>
            </div>
            <div className="home_step_5" onMouseOver={Explanation_5} onMouseOut={Explanation}>
              <div className="home_step_five">
                <h3>5</h3>
                <p>抽象能力</p>
              </div>
            </div>
            <div className="home_step_6" onMouseOver={Explanation_6} onMouseOut={Explanation}>
              <div className="home_step_six">
                <h3>6</h3>
                <p>模式提取</p>
              </div>
            </div>
            <div id="home_explanation_one" className="home_explanation_1" >
              <div className="home_explanation_jian"></div>
              <p>学会有效、有序地解构复杂的问题，将大问题拆解成小问题，最终逆向建构结构类知识和过程类知识</p>
            </div>
            <div id="home_explanation_two" className="home_explanation_2">
              <div className="home_explanation_jian"></div>
              <p>算法能力是编程和数学课程学习能力的综合表现，掌握了它就掌握了打开数理思维的启蒙大门，它由阅读算法能力、提炼算理能力、算法语言能力、应用算法能力四部分构成</p>
            </div>
            <div id="home_explanation_three" className="home_explanation_3">
              <div className="home_explanation_jian"></div>
              <p>和其他小工程师共同完成大型编程作品，发挥每个成员不同位置上的最大作用，让团队优势翻倍增长</p>
            </div>
            <div id="home_explanation_four" className="home_explanation_4">
              <div className="home_explanation_jian"></div>
              <p>玩出未来创意能力，经过充分系统的比较和可靠预测，灵活调整程序形态，从你的键盘下诞生更多创意作品</p>
            </div>
            <div id="home_explanation_five" className="home_explanation_5">
              <div className="home_explanation_jian"></div>
              <p>把具体变成概念，进而驾驭概念进行思考，从复杂的逻辑步骤中抓住事物的本质，让程序变得简单和有趣</p>
            </div>
            <div id="home_explanation_six" className="home_explanation_6">
              <div className="home_explanation_jian"></div>
              <p>解决抽象形式的分类问题，使用机器的思维方式解决计算机的问题，踏入人工智能的第一步</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// 教学模式
function TeachingModeC() {
  const Immediately = () => {
    var phone = document.getElementById('PHOne').value;
    if ((/^1[34578]\d{9}$/.test(phone))) {
      document.getElementById('errorformat').innerHTML = '';
      document.getElementById('Popupwindow').style.display = 'flex';
      document.getElementById('popup_tele').value = phone
    } else {
      document.getElementById('errorformat').innerHTML = '格式错误'
      return false;
    }
  }
  return (
    <div className="teachingmode">
      <div className="banner">
        <div className="bannerimg">
          <img src={BannerIMG} alt="" draggable='false' />
        </div>
        <div className="banner_free">
          <div className="banner_registered">
            <p className="bannerFree">注册领取</p>
            <p className="banner_valueOF">价值<span>399</span>元试听课</p>
            <div className="banner_input">
              <input id="PHOne" type="text" maxLength="11" placeholder="请输入手机号" />
              <span id="errorformat"></span>
            </div>
            <div className="banner_button" onClick={Immediately}>
              <img src={Quest} alt="" draggable='false' />
            </div>
          </div>
        </div>
      </div>
      <div className="FiveTeaching">
        <p className="childResult">FIVE教学法</p>
        <p className="eleYOYO">“学、练、测、赛、创”</p>
        <div>
          <img src={FiveTeach} alt="" draggable='false' />
        </div>
      </div>
      <div className="Teachingmode">
        <p className="childResult">教学模式</p>
        <p className="eleYOYO">YO小程多场景课堂</p>
        <div className="teachWay">
          <div className="way">
            <img src={Livec} alt="" draggable='false' />
            <h4>学霸直播课堂</h4>
            <p>面对面指导教学，再也不用担心辅导孩子作业</p>
          </div>
          <div className="way way_1">
            <img src={Smart} alt="" draggable='false' />
            <h4>AI动画录播课堂</h4>
            <p>课程可反复观看，可根据需求随时随地听课</p>
          </div>
          <div className="way">
            <img src={Interaction} alt="" draggable='false' />
            <h4>社群互动课堂</h4>
            <p>开学典礼、编程知识讲座毕业颁奖典礼都将在这里举行</p>
          </div>
        </div>
      </div>
      <div className="company">
        <p className="childResult">陪伴教学</p>
        <p className="eleYOYO">激发孩子自主性，贴心一对一服务</p>
        <div className="company_childResult">
          <div className="companyLeft">
            <img src={CompanyLeft} alt="" draggable='false' />
          </div>
          <div className="companyRight">
            <img src={CompanyRight} alt="" draggable='false' />
          </div>
        </div>
      </div>
    </div>
  )
}


// 上课流程
function ProcessC() {
  const Immediately = () => {
    var phone = document.getElementById('Phone').value;
    if ((/^1[34578]\d{9}$/.test(phone))) {
      document.getElementById('errorformat').innerHTML = '';
      document.getElementById('Popupwindow').style.display = 'flex';
      document.getElementById('popup_tele').value = phone
    } else {
      document.getElementById('errorformat').innerHTML = '格式错误'
      return false;
    }
  }
  return (
    <div className="processc">
      <div className="banner">
        <div className="bannerimg">
          <img src={BannerIMG} alt="" draggable='false' />
        </div>
        <div className="banner_free">
          <div className="banner_registered">
            <p className="bannerFree">注册领取</p>
            <p className="banner_valueOF">价值<span>399</span>元试听课</p>
            <div className="banner_input">
              <input id="Phone" type="text" maxLength="11" placeholder="请输入手机号" />
              <span id="errorformat"></span>
            </div>
            <div className="banner_button" onClick={Immediately}>
              <img src={Quest} alt="" draggable='false' />
            </div>
          </div>
        </div>
      </div>
      <div className="howtosee">
        <p className="childResult">如何看课</p>
        <div className="HowToSee">
          <img src={Howtosee} alt="" draggable='false' />
        </div>
      </div>
      <div className="handsup">
        <p className="childResult">动手编程</p>
        <div className="HandsUp">
          <img src={Handsup} alt="" draggable='false' />
        </div>
      </div>
      <div className="preparationc">
        <p className="childResult">课程准备</p>
        <div className="Preparationc">
          <img src={Preparation} alt="" draggable='false' />
        </div>
      </div>
    </div>
  )
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: 'white',
  },
}));

function ScrollableTabsButtonAuto() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const input = {
    phone: "",
    buttom: ""
  }

  const Immediately = () => {
    var phone = document.getElementById('bottom_tele').value;
    if ((/^1[34578]\d{9}$/.test(phone))) {
      document.getElementById('bottom_error').innerHTML = '';
      document.getElementById('Popupwindow').style.display = 'flex';
      document.getElementById('popup_tele').value = phone
    } else {
      document.getElementById('bottom_error').innerHTML = '格式错误'
      return false
    }
  }

  const handleClick = () => {
    axios({
      method: 'post',
      url: 'https://yoback.xzs51.com/api/sms/send',
      dataType: "json",
      data: Qs.stringify({
        mobile: document.getElementById('popup_tele').value,
        event: 'changemobile'
      })
    }).then(function (res) {
      if (res.data.code == 1) {
        alert(res.data.msg)
        document.getElementById('popup_span').innerHTML = "已成功发送";
      } else {
        alert(res.data.msg);
        input.buttom.disabled = false;
      }
      return false;
    })
  }

  const Closed_popup = () => {
    document.getElementById('Popupwindow').style.display = 'none'
    document.getElementById('Successful_CLASS').style.display = 'none'
  }

  const Popup_exp = () => {
    if (document.getElementById('popup_event').value != '') {
      document.getElementById('Popupwindow').style.display = 'none';
      document.getElementById('Successful_CLASS').style.display = 'flex' 
    } else {
      alert('验证码不能为空')
    }
  }

  const GoTo = () => {
    window.open('about:blank').location.href = 'https://yocode.com.cn/login'
  }

  const YOyo_href = () => {
    window.location.href = 'https://yocode.com.cn/'
  }

  const BeiAn = () => {
    window.open('about:blank').location.href = 'https://beian.miit.gov.cn/'
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" id="headerYO">
        <div className="YOyo">
          <img src={Img} alt="" draggable='false' onClick={YOyo_href} />
        </div>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          // variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          className="hometabs"
        >
          <Tab label="首页" {...a11yProps(0)} className="tabhome" />
          <Tab label="课程体系" {...a11yProps(1)} className="tabcurriculum" />
          <Tab label="教学模式" {...a11yProps(2)} className="tabteachingmode" />
          <Tab label="上课流程" {...a11yProps(3)} className="tabprocess" />
        </Tabs>
        <div className="gotostudy" onClick={GoTo}><span>登录学习系统</span></div>
      </AppBar>
      <TabPanel value={value} index={0}>
        <HomeTitle />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CurriculumC />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TeachingModeC />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ProcessC />
      </TabPanel>
      <div className="bottom_foot">
        <p className="bottom-message">地址: 河南省郑州市金水区文化路与红旗路交叉口西300米广厦城市之巅9号楼</p>
        <p className="bottom-dizhi">COPYRIGHT2020 行知塾教育集团 <span onClick={BeiAn}>豫ICP备19005091号-1</span></p>
      </div>
      <div className="bottom_get">
        <div className="bottomfooter">
          <div className="bottom_free">
            <div className="bottom_img">
              {/* <img src={Twochilds} alt="" /> */}
            </div>
            <p className="bottom_valueOF">注册领取价值<span>399元</span>体验课</p>
            <div className="bottom_input">
              <input id="bottom_tele" className="bottom_number" type="text" maxLength="11" placeholder="请输入手机号" />
              <span id="bottom_error"></span>
              <button id="bottom_Buttom" className="bottom_button" onClick={Immediately}>立即领取</button>
            </div>
          </div>
        </div>
      </div>
      <div id="Popupwindow" className="PopupWindow">
        <div className="PopupEX">
          <span className="Close_popup">
            <img src={ClosePopup} alt="" onClick={Closed_popup} draggable='false' />
          </span>
          <div className="popup_free">
            <p className="popup_get_1">注册领取</p>
            <p className="popup_get_2">价值<span>399元</span>编程体验课</p>
          </div>
          <div className="popup_quick">
            <div className="popup_quickly">
              <div className="popup_input">
                <input id="popup_tele" type="text" placeholder="请输入手机号" maxLength="11" name="mobile" ref={phone => input.phone = phone} />
              </div>
              <div className="popup_YZM">
                <input id="popup_event" type="text" placeholder="短信验证码" maxLength="4" />
                <span id="popup_span" onClick={handleClick} ref={buttom => input.buttom = buttom}>获取验证码</span>
              </div>
              <div className="popup_EXP">
                <img src={Experience} alt="" onClick={Popup_exp} draggable='false' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Successful_CLASS" className="Successful">
        <div className="Successful_code">
          <span className="Successful_Close">
            <img src={ClosePopup} alt="" onClick={Closed_popup} draggable='false' />
          </span>
          <div className="Successful_zero">
            <p className="Successful_look">和YOYO一起体验编程乐趣吧！</p>
          </div>
          <div className="yoyoQrcode">
            <img src={YOYOQrcode} alt="" draggable='false' />
          </div>
          <div className="Successful_focus">
            <p className="Successful_receive">关注【游研视界】在后台回复<span className="Successful_class">领取课程</span><br />即刻领取价值<span>399</span>元编程课<br /></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ScrollableTabsButtonAuto)