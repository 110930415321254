
import React from 'react';
import './demo.css';
import RightBut from '../images/rightBut.png';
import LeftBut from '../images/leftBut.png';
import Left_But from '../images/left_but.png';
import Right_But from '../images/right_but.png';

class SwipePlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      index: 0 // 当前轮播第几个图片
    }
  }
 
  // 点击播放下一张
  _nextImg () {
    var { index } = this.state;
    if (index === this.props.images.length - 1) {
      index = 0;
    } else {
      index++
    }
    this.setState({
      index: index
    })
  }
 
  // 点击播放上一张
  _prevImg () {
    var { index } = this.state;
    if (index === 0) {
      index = this.props.images.length - 1
    } else {
      index--
    }
    this.setState({
      index: index
    })
  }
 
  // 当鼠标停留在图片上时
  mouseHoverImg_1 () {
    document.getElementById('LeftBut_1').style.display = 'none';
    document.getElementById('LeftBut_2').style.display = 'block';clearInterval(this.timerId)
  }
  mouseHoverImg_2 () {
    document.getElementById('RightBut_1').style.display = 'none';
    document.getElementById('RightBut_2').style.display = 'block';clearInterval(this.timerId)
  }
  mouseLeaveImg_1 () {
    document.getElementById('LeftBut_2').style.display = 'none';
    document.getElementById('LeftBut_1').style.display = 'block';
    this.play()
  }
  mouseLeaveImg_2 () {
    document.getElementById('RightBut_2').style.display = 'none';
    document.getElementById('RightBut_1').style.display = 'block';this.play()
  }

  play () {
    this.timerId = setInterval(() => {
      this._nextImg()
    }, 3000)
  }
 
  componentDidMount () {
    this.play()
  }
  componentWillUnmount () {
    clearInterval(this.timerId)
  }
 
  render () {
    var { index } = this.state;
    return (
      <div className="wrap">
        <img id="LeftBut_1" src={LeftBut} alt="" className="btn left" onClick={() => this._prevImg()} onMouseOver={()=>this.mouseHoverImg_1()} draggable='false' />
        <img id="LeftBut_2" src={Left_But} alt="" className="btn left" onClick={() => this._prevImg()} onMouseLeave={()=>this.mouseLeaveImg_1()} draggable='false' />
        <ul className="list">
          {
            this.props.images.map((item, i) => (
              <li className={`item ${i === index ? 'active' : ''}`} key={i}
                onMouseOver={() => this.mouseHoverImg()}
                onMouseLeave={() => this.mouseLeaveImg()}>
                <img src={item} alt="" draggable='false' />
              </li>
            ))
          }
        </ul>
        <img id="RightBut_1" src={RightBut} alt="" className="btn right" onClick={() => this._nextImg()} onMouseOver={()=>this.mouseHoverImg_2()} draggable='false' />
        <img id="RightBut_2" src={Right_But} alt="" className="btn right" onClick={() => this._nextImg()} onMouseLeave={()=>this.mouseLeaveImg_2()} draggable='false' />
      </div>
    )
  }
}
 
export default SwipePlayer;