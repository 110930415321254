import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './myclass.scss';
import './personal.scss';
import './mywork.scss';
import './modal.scss';
import './MYheader.scss';
import './footer.scss';
import './mywork.scss';
import Player from '../images/player.png';
import Cog from '../images/cog2.png';
import Write from '../images/tianxie.png';
import Account from '../images/shezhi.png';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import axios from 'axios';
import Qs from 'qs';
import Img from '../images/yocode.png';
import Close from '../images/close.png';
import XiaLa from '../images/xiala.png';
import Lock from '../images/lock.png';
import Happy from '../images/happy.png';
import LearnMust from '../images/learn_must.png';
import Continue from '../images/continue.png';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import './upload.scss';

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  const storage = window.localStorage;
  const form = new FormData();
  form.append('token', storage.token);
  form.append('file', file);
  axios({
    method: 'post',
    url: 'http://yoback2.xzs51.com/api/common/upload',
    dataType: 'json',
    data: form
  }).then(res => {
    const storage = window.localStorage;
    if (res.data.code == 1) {
      console.log(res.data);
      storage.avatar = 'http://yoback2.xzs51.com/' + res.data.data.url;//头像
      document.getElementById('imgsrc').setAttribute("src", 'http://yoback2.xzs51.com' + res.data.data.url);
    } else {
      alert(res.data.msg);
    }
    return false;
  })

  return false;
}

class Avatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: '',
      loading: false,
      PersonalImg: window.localStorage.avatar ? window.localStorage.avatar : 'http://localhost:3000/static/media/yocode.0b1d620a.png'
    }
  }
  render() {
    const uploadButton = (
      <div id="UPload">
        <img id="imgsrc" src={this.state.PersonalImg} draggable="false" alt="" {...this.state.loading ? <LoadingOutlined /> : <PlusOutlined />} />
      </div>
    );
    const { imageUrl } = this.state;
    return (
      <Upload
        name="file"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
      >
        {imageUrl ? <img src={imageUrl} alt="file" style={{ width: '100%' }} draggable="false" /> : uploadButton}
      </Upload>
    );
  }
}



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: "relative"
  },
  // Modal: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // },
  // Paper: {
  //   backgroundColor: theme.palette.background.paper,
  //   border: '2px solid #000',
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 4, 3),
  // },
}));

// 我的课程主页
class MyclassHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      display: 'none',
      transform: 'rotate(0deg)',
      data: [{ id: '', title: '' }],
      chapter: '',
      chapter_list: [{ id: '', class_id: '', title: '', weigh: '', createtime: '' }],
      curriculum_one_list: [{ curriculum_id: '', chapter_id: '', sta_time: '', createtime: '', status: '', id: '', title: '', subtitle: '', image: '',is_guide: '' }],
      VideoImage: '',
      info: '',
      code: '',
      video_url: '',
      sta_time: '',
      VideoTitle: '',
      VideoSubTitle: '',
      classTitle: '',
      classSubTitle: '',
      classID: '',
      RecentT: {},

      active: '',
      index: '',
      Wao: ''
    }
    this.handleClick = this.handleClick.bind(this);
    this.Tiaozhuan = this.Tiaozhuan.bind(this);
    this.Tiao_Zhuan = this.Tiao_Zhuan.bind(this);
    this.ActiveList = this.ActiveList.bind(this);
    this.axiosData = this.axiosData.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    const storage = window.localStorage
    if (storage.recentT) {
      this.setState({
        RecentT: JSON.parse(storage.recentT)
      })
    }
    this.axiosData()
    this.setState({
      title: storage.title,
      image: storage.curriculumImage,
      video_url: "https://yoback.xzs51.com" + storage.videoURL,
      VideoTitle: storage.VideoTitle,
      VideoSubTitle: storage.VideoSubTitle,
      info: storage.info
    })
  }

  ActiveList(index) {
    console.log(index)
    const storage = window.localStorage
    axios({
      method: 'post',
      url: 'http://yoback2.xzs51.com/api/index/curriculumList',
      dataType: "json",
      data: Qs.stringify({
        token: storage.token,
        class_id: storage.class_id,
        chapter: index
      })
    }).then((res) => {
      const storage = window.localStorage;
      if (res.data.code == 1) {
        for (let i = 0; i < res.data.data.length; i++) {
          storage.ListTitle = res.data.data.chapter_list[i].title;
          storage.ListID = res.data.data.chapter_list[i].id;
          storage.curriculumTitle = res.data.data.curriculum_one_list[i].title;
          storage.curriculumID = res.data.data.curriculum_one_list[i].id;
          storage.curriculumSubTitle = res.data.data.curriculum_one_list[i].subtitle;
          storage.curriculumImage = res.data.data.curriculum_one_list[i].image;
          storage.curriculumIsGuide = res.data.data.curriculum_one_list[i].is_guide;
        }
        this.setState({
          chapter_list: res.data.data.chapter_list,
          curriculum_one_list: res.data.data.curriculum_one_list,
          classTitle: storage.curriculumTitle,
          classSubTitle: storage.curriculumSubTitle
        })
      } else {
        alert(res.data.msg);
      }
      // if(res.data.code = 401){
      //   window.location.href = '/login'
      // }
    })
    this.setState({
      Wao: index,
      active: index
    })
  }

  axiosData() {
    const storage = window.localStorage;
    axios({
      method: 'post',
      url: 'http://yoback2.xzs51.com/api/index/userPayClass',
      dataType: "json",
      data: Qs.stringify({
        token: storage.token
      })
    }).then((res) => {
      const storage = window.localStorage;
      if (res.data.code == 1) {
        for (let i = 0; i < res.data.data.length; i++) {
          storage.title = res.data.data[i].title;
          storage.class_id = res.data.data[i].id
        }
        this.setState({
          data: res.data.data,
          title: res.data.data[0].title,
        })
        this.getData(storage.class_id ,this.state.Wao)
      } else{
        alert(res.data.msg);
      }
      // if(res.data.code = 401){
      //   window.location.href = '/login'
      // }
      return false;
    })
  }

  getData(vl ,ca) {
    const storage = window.localStorage
    axios({
      method: 'post',
      url: 'http://yoback2.xzs51.com/api/index/curriculumList',
      dataType: "json",
      data: Qs.stringify({
        token: storage.token,
        class_id: vl,
        chapter: ca
      })
    }).then((res) => {
      const storage = window.localStorage;
      if (res.data.code == 1) {
        for (let i = 0; i < res.data.data.length; i++) {
          storage.ListTitle = res.data.data.chapter_list[i].title;
          storage.ListID = res.data.data.chapter_list[i].id;
          storage.curriculumTitle = res.data.data.curriculum_one_list[i].title;
          storage.curriculumID = res.data.data.curriculum_one_list[i].id;
          storage.curriculumSubTitle = res.data.data.curriculum_one_list[i].subtitle;
          storage.curriculumImage = res.data.data.curriculum_one_list[i].image;
        }
        this.setState({
          chapter_list: res.data.data.chapter_list,
          curriculum_one_list: res.data.data.curriculum_one_list,
          classTitle: storage.curriculumTitle,
          classSubTitle: storage.curriculumSubTitle,
          active: res.data.data.chapter_list[0].id
        })
      } else {
        alert(res.data.msg);
      }
      return false;
    }).catch(function (ress) {
      if(ress.data.code = 401){
        window.location.href = '/login'
      }
    })
  }

  myclass_F(T) {
    document.getElementById('Myclass_modal').style.display = 'flex';
    const storage = window.localStorage;
    axios({
      method: 'post',
      url: 'http://yoback2.xzs51.com/api/index/curriculumDetails',
      dataType: "json",
      data: Qs.stringify({
        token: storage.token,
        id: T.id
      })
    }).then((res) => {
      const storage = window.localStorage;
      if (res.data.code == 1) {
        storage.videoURL = res.data.data.video_url;
        storage.VideoTitle = res.data.data.title;
        storage.VideoSubTitle = res.data.data.subtitle;
        storage.info = res.data.data.info;
        storage.code = res.data.data.code;
        this.setState({
          video_url: "https://yoback.xzs51.com" + res.data.data.video_url,
          code: "http://yoback2.xzs51.com" + res.data.data.code,
          info: res.data.data.info
        })
      } else {
        alert(res.data.msg);
      }
      return false;
    })
    let recentT = {}
    recentT.id = T.id
    recentT.title = T.title
    recentT.subtitle = T.subtitle
    recentT.curriculum_id = T.curriculum_id
    recentT.is_guide = T.is_guide
    storage.recentT = JSON.stringify(recentT)
    this.setState({
      RecentT: recentT
    })
  }

  Myclass_B(){
    document.getElementById('Myclass_Big').style.transform = 'scale(1.05 ,1.05)'
  }

  Myclass_S(){
    document.getElementById('Myclass_Big').style.transform = 'scale(1 ,1)'
  }

  myclass_D() {
    document.getElementById('Myclass_modal').style.display = 'flex';
  }

  Myclass_lose() {
    alert('该课程还未解锁，请先前往观看已解锁课程')
  }

  handleClick() {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn,
      display: prevState.isToggleOn ? 'block' : 'none',
      transform: prevState.isToggleOn ? 'rotate(180deg)' : 'rotate(0deg)'
    }));
  }

  changeClick(data) {
    this.setState({
      title: data.title,
      classID: data.id
    })
    this.getData(data.id)
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn,
      display: prevState.isToggleOn ? 'block' : 'none',
      transform: prevState.isToggleOn ? 'rotate(180deg)' : 'rotate(0deg)'
    }))
  }

  myclass_first(index) {
    this.setState({
      index: index
    })
  }

  myclass_First() {
    this.setState({
      index: ''
    })
  }

  Tiaozhuan() {
    const storage = window.localStorage
    if (storage.recentT && JSON.parse(storage.recentT).id) {
      window.open('about:blank').location.href = 'http://scratch.xzs51.com/index.html?token=' + storage.token + '&class_id=' + storage.class_id + '&chapter_id=' + this.state.Wao + '&curriculum_id=' + JSON.parse(storage.recentT).curriculum_id
    } else {
      window.open('about:blank').location.href = 'http://scratch.xzs51.com/index.html?token=' + storage.token
    }
  }

  Tiao_Zhuan(OH ,WA) {
    const storage = window.localStorage
    window.open('about:blank').location.href = 'http://scratch.xzs51.com/index.html?token=' + storage.token + '&class_id=' + storage.class_id + '&chapter_id=' + WA + '&curriculum_id=' + OH
  }

  myclass_Close() {
    document.getElementById('Myclass_modal').style.display = 'none'

    // 视频从零开始
    // document.getElementById('myVideo').currentTime = 0

    // 视频暂停
    document.getElementById('myVideo').pause()

    //获取视频当前时长
    // video.addEventListener("timeupdate",function(){
    //   var timers=Math.ceil(this.currentTime);//视频当前播放时长
    //   time1.innerHTML=timeToMinute(timers);
    // })
  }

  render() {

    return (
      <div className="Myclass">
        <div className="myclass">
          <div>
            <div className="Myclass_ClassType">
              <h2>当前班型：{this.state.title}</h2>
              <div className="Myclass_Switch" onClick={this.handleClick}>
                <span>切换班型</span>
                <img id="Myclass_Xiala" src={XiaLa} alt="" style={{ transform: this.state.transform }} draggable="false" />
              </div>
              <div id="Myclass_transform" className="Myclass_Transform" style={{ display: this.state.display }}>
                <div className="Myclass_jian"></div>
                <ul>
                  {
                    this.state.data.map((data) =>
                      <li key={data.id} onClick={this.changeClick.bind(this, data)}>{data.title}</li>
                    )
                  }
                </ul>
              </div>
            </div>
            <div className="Myclass_Recently">
              <div className="Myclass_Recent">
                <h3>最近学习课程</h3>
                <p>{this.state.title}<span>{this.state.RecentT.title}</span></p>
              </div>
              <div id="Myclass_Big" className="Myclass_continue">
                <img onClick={this.myclass_D} src={Continue} alt="" draggable="false" onMouseOver={this.Myclass_B} onMouseOut={this.Myclass_S} />
              </div>
            </div>
          </div>
          <div className="Myclass_Back">
            <div className="myclasslevel">
              <ul>
                {
                  this.state.chapter_list.map((item, index) => {

                    return (
                      <li
                        className={`myclasslevel2 ${this.state.active == item.id ? 'myclasslevel1' : ''}`}
                        key={index}
                        onClick={this.ActiveList.bind(this, item.id)}
                      >
                        {item.title}
                      </li>
                    )
                  })
                }
              </ul>
            </div>
            <div className="myclassVideo">
              <div className="myclass_class">
                {
                  this.state.curriculum_one_list.map((item, index) => {
                    return (
                      <div key={index} className={`myclassvideo ${this.state.index == item.curriculum_id ? 'myclass_index' : ''}`} id="myclass_video">
                        {
                          item.status == 1 && item.is_guide == 0 &&
                          <div>
                            <div className="myclassImg" onMouseOver={this.myclass_first.bind(this, item.curriculum_id)} onMouseOut={this.myclass_First.bind(this)} onClick={this.myclass_F.bind(this, item)}>
                              <img className="Myclass_BackImg" src={"http://yoback2.xzs51.com" + item.image} alt="" />
                              <div id="Myclass_first" className="myclassmask">
                                <div className="myclass_img">
                                  <img src={Player} alt="" className="Img-1" draggable="false" />
                                </div>
                              </div>
                            </div>
                            <div className="myclasstext">
                              <span className="myclassname">{item.title}</span>
                              <button className="myclassButton" onClick={this.Tiao_Zhuan.bind(this, item.curriculum_id, item.chapter_id)}>打开工程</button>
                            </div>
                          </div>
                        }
                        {
                          item.status == 1 && item.is_guide == 1 &&
                          <div>
                            <div className="myclassImg" onMouseOver={this.myclass_first.bind(this, item.curriculum_id)} onMouseOut={this.myclass_First.bind(this)} onClick={this.myclass_F.bind(this, item)}>
                              <img className="Myclass_BackImg" src={"http://yoback2.xzs51.com" + item.image} alt="" />
                              <div id="Myclass_first" className="myclassmask">
                                <div className="myclass_img">
                                  <img src={Player} alt="" className="Img-1" draggable="false" />
                                </div>
                              </div>
                            </div>
                            <div className="myclasstext">
                              <span className="myclassname">{item.title}</span>
                              <button className="myclassButton" onClick={this.myclass_F.bind(this, item)}>观看导学课</button>
                            </div>
                          </div>
                        }
                        {
                          item.status == 0 &&
                          <div>
                            <div className="myclassImg" onClick={this.Myclass_lose}>
                              <img className="Myclass_BackImg" src={"http://yoback2.xzs51.com" + item.image} alt="" />
                              <div id="Myclass_first" className="myclass_mask">
                                <div className="myclass_img">
                                  <img src={Lock} alt="" className="Img-1" draggable="false" />
                                </div>
                              </div>
                            </div>
                            <div className="myclasstext">
                              <span className="myclassname">{item.title}</span>
                              <button className="myclassButton" onClick={this.Tiaozhuan.bind(this, item.curriculum_id, item.chapter_id)}>打开工程</button>
                            </div>
                          </div>
                        }
                      </div>
                    )
                  })
                }
                <div className="clear"></div>
              </div>
            </div>
          </div>
        </div>
        <div id="Myclass_modal" className="Myclass_Modal">
          <div className="myclass_classvideo">
            <h2 id="spring-modal-title firstTitle" className="myclass_video_title">{this.state.RecentT.title}<img src={Close} alt="" className="myclass_Closed" onClick={this.myclass_Close} /></h2>
            <video id="myVideo" src={this.state.video_url} type="video/mp4" controls autoplay loop controlsList="nodownload" onContextMenu="return false"></video>
            {/* <div id="current">0:00</div>
            <div id="duration">0:00</div> */}
            {
              this.state.RecentT.is_guide == 0 &&
              <div className="myclass_details">
                <div className="myclass_Dl">
                  <h3>课程简介:</h3>
                  <p className="myclass_info">{this.state.info}</p>
                </div>
                <div className="myclass_Br">
                  <button className="myclass_Button" onClick={this.Tiaozhuan}>打开工程</button>
                </div>
              </div>
            }
            {
              this.state.RecentT.is_guide == 1 &&
              <div className="myclass_details">
                <div className="myclass_Dl">
                  <h3>课程简介:</h3>
                  <p className="myclass_info">{this.state.info}</p>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

// 个人中心 完课数据
class SubmitWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taskNumber: 0,
      classEndNumber: 0,
      studyTime: 0
    }
  }

  componentDidMount() {
    const storage = window.localStorage;
    axios({
      method: 'post',
      url: 'http://yoback2.xzs51.com/api/index/taskNumber',
      dataType: "json",
      data: Qs.stringify({
        token: storage.token
      })
    }).then(function (res) {
      const storage = window.localStorage;
      if (res.data.code == 1) {
        storage.taskNumber = res.data.data.taskNumber;
        storage.classEndNumber = res.data.data.classEndNumber;
        storage.studyTime = res.data.data.studyTime;
      } else {
        alert(res.data.msg);
      }
      return false;
    })
    this.setState({
      taskNumber: storage.taskNumber,
      classEndNumber: storage.classEndNumber,
      studyTime: storage.studyTime
    })
  }

  render() {
    return (
      <div className="personalCenter">
        <div className="cishu-style cishu-one">
          <p className="cishu">完成作业</p>
          <div className="study">
            <span className="cishunumber">{this.state.taskNumber}</span>
            <span className="cishuicon">/</span>
            <span className="cishutext">次</span>
          </div>
        </div>
        <div className="cishu-style cishu-two">
          <p className="cishu">完成课程</p>
          <div className="study">
            <span className="cishunumber">{this.state.classEndNumber}</span>
            <span className="cishuicon">/</span>
            <span className="cishutext">节</span>
          </div>
        </div>
        <div className="cishu-style cishu-three">
          <p className="cishu">学习时间</p>
          <div className="study">
            <span className="cishunumber">{this.state.studyTime}</span>
            <span className="cishuicon">/</span>
            <span className="cishutext">分钟</span>
          </div>
        </div>
      </div>
    )
  }
}

// 我的作业
class MyWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      display: 'none',
      transform: 'rotate(0deg)',
      data: [{
        id: '',
        title: ''
      }],
      ChapterList: [{
        id: '',
        class_id: '',
        title: '',
      }],
      dataList: [{
        id: '',
        title: '',
        subtitle: '',
				image: '',
        task_list: []
      }],
      index: '',
      active: ''
    }
    this.handleClick = this.handleClick.bind(this);
    this.Mywork_Show = this.Mywork_Show.bind(this);
    this.Mywork_Hide = this.Mywork_Hide.bind(this);
    this.Mywork_Href = this.Mywork_Href.bind(this);
    this.ajaxData = this.ajaxData.bind(this);
    this.Learn_Must = this.Learn_Must.bind(this);
  }

  componentDidMount() { 
    this.ajaxData()
  }

  Mywork_Show(index) {
    this.setState({
      index: index
    })
  }

  Mywork_Hide() {
    this.setState({
      index: ''
    })
  }

  Mywork_Href(TZ, CC, BB, DD) {
    const storage = window.localStorage;
    window.open('about:blank').location.href = 'http://scratch.xzs51.com/index.html?token=' + storage.token + '&class_id=' + CC + '&chapter_id=' + BB + '&curriculum_id=' + DD + '&task_id=' + TZ
  }

  handleClick() {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn,
      display: prevState.isToggleOn ? 'block' : 'none',
      transform: prevState.isToggleOn ? 'rotate(180deg)' : 'rotate(0deg)'
    }));
  }

  getClick(data) {
    this.setState({
      title: data.title,
      classID: data.id
    })
    this.LookData(data.id)
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn,
      display: prevState.isToggleOn ? 'block' : 'none',
      transform: prevState.isToggleOn ? 'rotate(180deg)' : 'rotate(0deg)'
    }));
  }

  ActiveList(index) {
    const storage = window.localStorage
    axios({
      method: 'post',
      url: 'http://yoback2.xzs51.com/api/index/userTaskList',
      dataType: "json",
      data: Qs.stringify({
        token: storage.token,
        class_id: storage.class_id,
        chapter_id: index
      })
    }).then((res) => {
      const storage = window.localStorage
      if (res.data.code == 1) {
        for (let i = 0; i < res.data.data.length; i++) {
          storage.curriculumIdImg = res.data.data.curriculum_list.image;
          storage.curriculumIdTitle = res.data.data.curriculum_list.title;
          storage.create_time_text = res.data.data.task_list.create_time_text;
          storage.taskFile = res.data.data.task_list.task_file;

        }
        this.setState({
          ChapterList: res.data.data.chapter_list,
          dataList: res.data.data.data_list
        })

      } else {
        alert(res.data.msg)
      }
      return false;
    })
    this.setState({
      active: index
    })
  }

  Learn_Must(){
    window.location.href = 'http://yocode.xingzhishujy.com/myclass'
  }

  ajaxData() {
    const storage = window.localStorage
    axios({
      method: 'post',
      url: 'http://yoback2.xzs51.com/api/index/userPayClass',
      dataType: "json",
      data: Qs.stringify({
        token: storage.token,
      })
    }).then((res) => {
      const storage = window.localStorage
      if (res.data.code == 1) {
        this.setState({
          data: res.data.data,
          title: res.data.data[0].title
        })
        this.LookData(storage.class_id, storage.chapter_id)
      } else {
        alert(res.data.msg)
      }
      return false;
    })
    this.setState({
      title: storage.title,
      image: storage.curriculumIdImg,
      task_file: storage.taskFile,
      create_time_text: storage.create_time_text
    })
  }

  LookData(vl, ci) {
    const storage = window.localStorage
    axios({
      method: 'post',
      url: 'http://yoback2.xzs51.com/api/index/userTaskList',
      dataType: "json",
      data: Qs.stringify({
        token: storage.token,
        class_id: vl,
        chapter_id: ci
      })
    }).then((res) => {
      const storage = window.localStorage
      if (res.data.code == 1) {
        for (let i = 0; i < res.data.data.length; i++) {
          storage.curriculumIdImg = res.data.data.curriculum_list.image;
          storage.curriculumIdTitle = res.data.data.curriculum_list.title;
          storage.create_time_text = res.data.data.task_list.create_time_text;
          storage.taskFile = res.data.data.task_list.task_file;
        }
        this.setState({
          ChapterList: res.data.data.chapter_list,
          dataList: res.data.data.data_list,
          active: res.data.data.chapter_list[0].id
        })
      } else {
        alert(res.data.msg)
      }
      return false;
    })
  }

  render() {

    return (
      <div className="Mywork_Back">
        <div className="Mywork_Box">
          <div className="Mywork_ClassType">
            <h2>{this.state.title}</h2>
            <div className="Mywork_Switch" onClick={this.handleClick}>
              <span>切换班型</span>
              <img id="Mywork_Xiala" src={XiaLa} alt="" style={{ transform: this.state.transform }} draggable="false" />
            </div>
            <div className="Mywork_Transform" style={{ display: this.state.display }}>
              <div className="Mywork_jian"></div>
              <ul>
                {
                  this.state.data.map((data) =>
                    <li key={data.id} onClick={this.getClick.bind(this, data)}>{data.title}</li>
                  )
                }
              </ul>
            </div>
          </div>
          <div className="Mywork_Chapter">
            <div className="Mywork_ChapterLevel">
              <ul>
                {
                  this.state.ChapterList.map((dl, index) => {
                    return (
                      <li key={index} className={`Mywork_ChapterLevel_02 ${this.state.active == dl.id ? 'Mywork_ChapterLevel_01' : ''}`} onClick={this.ActiveList.bind(this, dl.id)}>{dl.title}</li>
                    )
                  })
                }
              </ul>
            </div>
            <div className="Mywork_class">
              {
                !this.state.dataList &&
                <div id="Mywork_happy" >
                  <div style={{marginTop:'60px' , marginLeft:'400px'}}>
                    <img src={Happy} alt="" draggable="false" />
                  </div>
                  <p style={{textAlign:'center' , fontSize:'20px' , lineHeight:'36px' , fontFamily:'PingFang SC'}}>你还没有提交作业哟，快去写作业吧！</p>
                  <div style={{margin:'50px 0px 258px 462px'}} onClick={this.Learn_Must}>
                    <img src={LearnMust} alt="" draggable="false" style={{cursor:'pointer'}} />
                  </div>
                </div>
              }
              {
                this.state.dataList && 
                this.state.dataList.map((item, index) => {
                  return (
                    <div>
                      {
                        item.task_list.length !== 0  &&
                        <h3 key={index}>{item.title}</h3>
                      }
                      <div className="mywork_Workcode">
                        {
                          item.task_list.map((ChapterL, ChapterI) => {
                            return (
                              <div key={ChapterI} id="Mywork_Workcode" className={`Mywork_WorkCode ${this.state.index == ChapterL.id ? 'mywork_index' : ''}`}>
                                <div className="Mywork_Img" onMouseOver={this.Mywork_Show.bind(this, ChapterL.id)} onMouseOut={this.Mywork_Hide.bind(this)}>
                                  <img className="Myclass_BackImg" src={"http://yoback2.xzs51.com" + item.image} alt="" draggable="false" />
                                  <div id="Mywork_mask" className="Mywork_Mask" onClick={this.Mywork_Href.bind(this, ChapterL.id, ChapterL.class_id, ChapterL.chapter_id, item.id)}>
                                    <button className="mywork_Button">打开工程</button>
                                  </div>
                                </div>
                                <div className="Mywork_Title">
                                  <div className="Mywork_Works"> 
                                    <h4>{ChapterL.name}</h4>
                                    <p>{ChapterL.create_time_text}</p>
                                  </div>
                                  <div id="Mywork_Notthrough">
                                    {
                                      ChapterL.switch == 0 && ChapterL.type == 1 && <span className="Mywork_ITG">批改中</span>
                                    }
                                    {
                                      ChapterL.switch == 1 && ChapterL.type == 1 && <span className="Mywork_State">已通过</span>
                                    }
                                    {
                                      ChapterL.switch == 2 && ChapterL.type == 1 && <span className="Mywork_NotThrough">未通过</span>
                                    }
                                    {
                                      ChapterL.type == 0 && <span className="Mywork_Practise">练习</span>
                                    }
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                        <div className="clear"></div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const input = {
    phone: "",
    captcha: "",
    newpassword: "",
    buttom: "",
    nickname: "",
    bio: ""
  }

  const UserNickName = {
    NickName: window.localStorage.nickname ? window.localStorage.nickname : "快设置一个自己喜欢的昵称吧",
    Bio: window.localStorage.bio ? window.localStorage.bio : "设置个性签名",
  }

  const OnHref = () => {
    window.open('about:blank').location.href = '/'
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCLose = () => {
    setOpen(false);
  };

  const handleClose = () => {
    if (!input.phone.value && !input.newpassword.value) {
      setOpen(false);
      return false;
    }
    axios({
      method: 'post',
      url: 'http://yoback2.xzs51.com/api/user/resetpwd',
      dataType: "json",
      data: Qs.stringify({
        type: "mobile",
        mobile: input.phone.value,
        newpassword: input.newpassword.value,
        captcha: input.captcha.value
      })
    }).then(function (res) {
      if (res.data.code == 1) {
        const storage = window.localStorage;
        storage.token = '';
        storage.user_id = '';
        storage.username = '';
        storage.nickname = '';
        storage.avatar = '';
        alert(res.data.msg + ',请重新登录!')
        setOpen(false);
        window.location = '/login';
      } else {
        alert(res.data.msg);
        // window.location = '/';
      }
      return false;
    })
  };

  const handleClick = () => {
    input.buttom.disabled = true;
    axios({
      method: 'post',
      url: 'http://yoback2.xzs51.com/api/sms/send',
      dataType: "json",
      data: Qs.stringify({
        mobile: input.phone.value,
        event: 'register'
      })
    }).then(function (res) {
      // const storage = window.localStorage;
      if (res.data.code == 1) {
        //定时器
        // storage.token = res.data.data.userinfo.token;//令牌
        // storage.user_id = res.data.data.userinfo.id;//用户ID
        // storage.username = res.data.data.userinfo.username;//用户手机号
        // storage.nickname = res.data.data.userinfo.nickname;//昵称
        // storage.avatar = res.data.data.userinfo.avatar;//头像
        // if(storage.token){
        //     window.location = '/myclass';
        // }else{
        //     alert('登录异常');
        // }
        alert(res.data.msg)
      } else {
        alert(res.data.msg);
        input.buttom.disabled = false;
        // window.location = '/';
      }
      return false;
    })
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let storage = window.localStorage;
  if (!storage.token || !storage.user_id) {
    window.location.href = '/login';
    return false;
  }

  const WriteM = () => {
    document.getElementById('Qianming').style.display = 'none';
    document.getElementById('Nicheng').style.display = 'none';
    document.getElementById('writeM').style.display = 'none';

    document.getElementById('QianMing').style.display = 'inline-block';
    document.getElementById('NiCheng').style.display = 'inline-block';
    document.getElementById('WRiteM').style.display = 'inline-block';
  }

  const handleSubmit = () => {
    const storage = window.localStorage;
    const form = new FormData();
    form.append('token', storage.token);
    form.append('nickname', '快设置一个自己喜欢的昵称吧');
    form.append('bio', '签名');
    if (input.nickname.value == '') {
      // window.location = '/';
      alert('昵称不能为空');
      return false;
    }
    axios({
      method: 'post',
      url: 'http://yoback2.xzs51.com/api/user/profile',
      dataType: "json",
      data: form
    }).then(function (res) {
      // const storage = window.localStorage;
      if (res.data.code == 1) {
        // console.log(res);

        document.getElementById('WRiteM').style.display = "none";

        document.getElementById('writeM').style.display = "inline-block";
        document.getElementById('writeM').innerHTML = "修改信息"

        document.getElementById('NiCheng').style.display = 'none';
        document.getElementById('UNCI').style.display = 'none';

        // console.log(input.bio.value)

        if (input.bio.value) {
          document.getElementById('QianMing').style.display = 'none';
          document.getElementById('Qianming').style.display = 'inline-block';
          document.getElementById('UQMI').style.display = 'none';
          document.getElementById('Qianming').innerHTML = input.bio.value;
          storage.bio = input.bio.value
        } else if (res.data.code == 401) {
          alert(res.data.msg);
          window.location.href = 'login'

          storage.bio = "";
        }
        document.getElementById('Nicheng').style.display = 'inline-block';
        document.getElementById('Nicheng').innerHTML = input.nickname.value;

        // storage.token = res.data.data.userinfo.token;//令牌
        storage.nickname = input.nickname.value;
      }
      return false;
    })
  }

  const BeiAn = () => {
    window.open('about:blank').location.href = 'http://beian.miit.gov.cn/'
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className="myclassheader">
        <div className="yo">
          <img src={Img} alt="" draggable="false" onClick={OnHref} />
        </div>
        {/* <div>
          <img src={Person} alt="" className="personIMG" draggable="false" />
        </div>
        <div>
          <img src={work} alt="" className="workIMG" draggable="false" />
        </div> */}
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab className="myclasstitle" label="我的课程" {...a11yProps(0)} />
          <Tab className="personalcentertitle" label="个人中心" {...a11yProps(1)} />
          <Tab className="myworktitle" label="我的作品" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <MyclassHome />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="personalcenter">
          <SubmitWork />
          <div className="personaldata">
            <div className="personalmessage">
              <div className="personalwrite">
                <img src={Write} alt="" draggable="false" />
                <span className="message">填写信息</span>
              </div>
              <div className="personal">
                <div className="personalsave">
                  <Avatar />
                </div>
                <div className="personalnicheng" id="PersonalNicheng">
                  <div className="personalclear-1">
                    <img src={Account} alt="" id="UNCI" draggable="false" />
                    <span className="nicheng" id="Nicheng">{UserNickName.NickName}</span>
                    <input type="text" border="0" id="NiCheng" placeholder="快设置一个自己喜欢的昵称吧" name="nickname" ref={nickname => input.nickname = nickname} value={input.nickname.value} />
                  </div>
                  <div className="personalclear-2">
                    <img src={Account} alt="" id="UQMI" draggable="false" />
                    <span className="qianming" id="Qianming">{UserNickName.Bio}</span>
                    <input type="text" border="0" id="QianMing" placeholder="设置个性签名" name="bio" ref={bio => input.bio = bio} value={input.bio.value} />
                  </div>
                </div>
              </div>
              <Button
                className="writemessage"
                onClick={WriteM}
              >
                <span id="writeM">修改信息</span>
                <span id="WRiteM" onClick={handleSubmit}>保存信息</span>
              </Button>
            </div>
            <div className="account">
              <div className="accountsetup">
                <img src={Cog} alt="" draggable="false" />
                <span>账户设置</span>
              </div>
              <div className="accountpassword">
                <h3>登录密码</h3>
                <p>建议您定期更换密码，设置安全性高的密码可以<br />使账号更安全</p>
                <button type="button" onClick={handleOpen} className="modify">修改</button>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={open}
                  // onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open}>
                    <div className={classes.paper}>
                      <input type="text" placeholder="请输入手机号" name="mobile" className="oldpw" ref={phone => input.phone = phone} />
                      <br />
                      <input type="text" placeholder="请输入验证码" name="captcha" className="newpw" ref={captcha => input.captcha = captcha} />
                      <button className="sendcaptcha" onClick={handleClick} ref={buttom => input.buttom = buttom} >发送验证码</button>
                      <br />
                      <input type="text" placeholder="请输入新密码" name="newpassword" className="returnpw" ref={newpassword => input.newpassword = newpassword} />
                      <br />
                      <button
                        onClick={handleClose}
                        className="surepw"
                      >
                        确定
                      </button>
                      <img src={Close} alt="" className="Close" onClick={handleCLose} draggable="false" />
                    </div>
                  </Fade>
                </Modal>
              </div>
              {/* <div className="accounttelephone">
                <h3>安全手机 155******12</h3>
                <p>安全手机可以用于登录账号，重置密码或其他安全验证</p>
                <button type="button" onClick={HandleOpen} className="modify">更换</button>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.Modal}
                  open={Open}
                  onClose={HandleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={Open}>
                    <div className={classes.paper}>
                    </div>
                  </Fade>
                </Modal>
              </div> */}
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MyWork />
      </TabPanel>
      <div className="myclassfooter">
        <div className="foot-message">
          <p>地址: 河南省郑州市金水区文化路与红旗路交叉口西300米广厦城市之巅9号楼</p>
          <p className="footer-1">COPYRIGHT2020 行知塾教育集团 <span onClick={BeiAn}>豫ICP备19005091号-1</span></p>
        </div>
      </div>
    </div>

  );
}
